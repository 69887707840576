<template>
  <div class="content">
    <img class="rf-model-img" :src="background" />
  </div>
</template>

<script>
export default {
  name: "HomeContentModel",
  data() {
    return {
      background: require("../../assets/images/home-content-model-bg.png")
    };
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global";
.rf-model-img {
  width: 100%;
  object-fit: contain;
}
</style>
