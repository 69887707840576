var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section-box core-business"},[_c('div',{staticClass:"content"},[_c('h3',[_vm._v("专业领域")]),_c('h6',[_vm._v("三大核心领域，专业的场景化解决方案")]),_c('div',{staticClass:"core-item-box"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,ref:item.className,refInFor:true,class:['item', item.className, item.isActive],on:{"mouseover":function($event){return _vm.mouseOver(item, index, _vm.items)}}},[_c('div',{attrs:{"data-aos":"fade-up","data-aos-delay":item.delay}},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(item.text))])])])}),0),_c('div',{ref:"arrow",staticClass:"arrow"})])]),_c('div',{staticClass:"business-intro"},[(this.isMenuShow === 'core-iot')?_c('div',{class:['business-item', 'content']},[_vm._m(0),_vm._m(1)]):_vm._e(),(this.isMenuShow === 'core-huanb')?_c('div',{class:['business-item', 'content']},[_vm._m(2),_vm._m(3)]):_vm._e(),(this.isMenuShow === 'core-water')?_c('div',{class:['business-item', 'content']},[_vm._m(4),_vm._m(5)]):_vm._e(),(this.isMenuShow === 'core-enterprises')?_c('div',{class:['business-item', 'content']},[_vm._m(6),_vm._m(7)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"business-title"},[_c('b',[_vm._v("工业物联网")]),_c('span',[_vm._v("信息技术与现代化生产深度融合、迭代演进")])]),_c('div',[_c('a',{staticClass:"btn btn-white",attrs:{"href":"/enterprises-digital"}},[_vm._v("了解更多")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business-content"},[_c('div',{attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"cont-icon cont-icon-two"}),_c('div',{staticClass:"tips"},[_c('span',[_vm._v("生产")]),_vm._v("·智能")]),_c('div',{staticClass:"cont-intro"},[_c('p',[_vm._v("生产设备智慧化"),_c('br'),_vm._v("生产设备 · 物联智慧")]),_c('div',[_vm._v(" 管理高效化、监管精细化"),_c('br'),_vm._v("工业物联网、智慧校园、智慧社区"),_c('br'),_vm._v("… ")])]),_c('div',{staticClass:"cont"})])]),_c('div',{attrs:{"data-aos":"fade-up","data-aos-delay":"300"}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"cont-icon cont-icon-three"}),_c('div',{staticClass:"tips"},[_c('span',[_vm._v("企业")]),_vm._v("·繁荣")]),_c('div',{staticClass:"cont-intro"},[_c('p',[_vm._v("经济产业智慧化"),_c('br'),_vm._v("经济发展 · 产业智慧")]),_c('div',[_vm._v(" 产业数字化、数字产业化"),_c('br'),_vm._v("工业互联网、安全生产、电子商务"),_c('br'),_vm._v("… ")])]),_c('div',{staticClass:"cont"})])]),_c('div',{attrs:{"data-aos":"fade-up","data-aos-delay":"500"}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"cont-icon cont-icon-one"}),_c('div',{staticClass:"tips"},[_c('span',[_vm._v("客户")]),_vm._v("·幸福")]),_c('div',{staticClass:"cont-intro"},[_c('p',[_vm._v("客户服务智慧化"),_c('br'),_vm._v("社会治理 · 人文智慧")]),_c('div',[_vm._v(" 服务优准化、民生幸福感"),_c('br'),_vm._v("智慧教育、智慧医疗、智慧养老"),_c('br'),_vm._v("… ")])]),_c('div',{staticClass:"cont"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"business-title"},[_c('b',[_vm._v("企业数字化")]),_c('span',[_vm._v("覆盖生态环境全数据，为中国生态保驾护航")])]),_c('div',[_c('a',{staticClass:"btn btn-white",attrs:{"href":"/wisdom-env-solution"}},[_vm._v("了解更多")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business-content"},[_c('div',{staticClass:"data-aos",attrs:{"data-aos":"fade-up"}},[_c('ul',[_c('li',[_c('span',[_vm._v("大气环境监测")]),_c('span',[_vm._v("企业电量监控")]),_c('span',[_vm._v("AI图像识别")]),_c('span',[_vm._v("污染源特征分析")]),_c('span',[_vm._v("空气质量预报")])]),_c('li',[_c('span',[_vm._v("环境综合分析")]),_c('span',[_vm._v("网格监管")]),_c('span',[_vm._v("重污染天气应对")]),_c('span',[_vm._v("空气质量评估")]),_c('span',[_vm._v("节能减排评估")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"business-title"},[_c('b',[_vm._v("水务数字化")]),_c('span',[_vm._v("供水排水水务全流程管理，为城市生活供给生命源泉")])]),_c('div',[_c('a',{staticClass:"btn btn-white",attrs:{"href":"/water-digital"}},[_vm._v("了解更多")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business-content"},[_c('div',{staticClass:"data-aos",attrs:{"data-aos":"fade-up"}},[_c('ul',[_c('li',[_c('span',[_vm._v("水源水质监测")]),_c('span',[_vm._v("供排水管理")]),_c('span',[_vm._v("GIS管网")]),_c('span',[_vm._v("直饮水水质监测")]),_c('span',[_vm._v("水利管理")])]),_c('li',[_c('span',[_vm._v("防汛抗旱")]),_c('span',[_vm._v("SCADA运行调度")]),_c('span',[_vm._v("河湖长制管理")]),_c('span',[_vm._v("海绵城市")]),_c('span',[_vm._v("污水处理监测")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"business-title"},[_c('b',[_vm._v("企业数字化")]),_c('span',[_vm._v("让企业运行有轨迹有方向")])]),_c('div',[_c('a',{staticClass:"btn btn-white",attrs:{"href":"/industrial-iot"}},[_vm._v("了解更多")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"business-content"},[_c('div',{staticClass:"data-aos",attrs:{"data-aos":"fade-up"}},[_c('ul',[_c('li',[_c('span',[_vm._v("数字化咨询设计")]),_c('span',[_vm._v("数字化过程评估服务")]),_c('span',[_vm._v("定制化开发服务")]),_c('span',[_vm._v("运维服务")])])])])])
}]

export { render, staticRenderFns }