var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"hc-header"},[_c('div',{staticClass:"hc-logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/svg/logo.svg"),"alt":"hc","title":"睿峰华创"}})])],1),_c('div',{staticClass:"hc-nav"},_vm._l((_vm.headerNav),function(nav,index){return _c('div',{key:index,class:[
          'nav-link-wrap',
          nav.name !== 'product' ? 'relative-pos' : ''
        ],on:{"mouseover":function($event){return _vm.toggle(index)},"mouseleave":function($event){_vm.active = -1}}},[(nav.direction)?_c('a',{class:[
            'main-nav-link',
            _vm.activeCurNav ? '1' : '2',
            _vm.activeCurNav.indexOf(nav.name) > -1 ? 'a-active' : ''
          ],attrs:{"exact":index === 0,"href":`${nav.direction}`}},[_vm._v(_vm._s(nav.title))]):_c('a',{class:[
            'main-nav-link',
            _vm.activeCurNav ? '1' : '2',
            _vm.activeCurNav.indexOf(nav.name) > -1 ? 'a-active' : ''
          ],attrs:{"exact":index === 0}},[_vm._v(_vm._s(nav.title))]),(_vm.active === index && nav.name === 'solution')?_c('drop-down-menu',{attrs:{"menu-items":_vm.solutionMenuItems},on:{"hideOnClick":_vm.hideOnClick}}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('product-navigation-box',{directives:[{name:"show",rawName:"v-show",value:(_vm.active === index && nav.name === 'product'),expression:"active === index && nav.name === 'product'"}]})],1)],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }