<template>
  <div class="header-wrapper">
    <div class="hc-header">
      <div class="hc-logo">
        <router-link to="/">
          <img src="../assets/svg/logo.svg" alt="hc" title="睿峰华创" />
        </router-link>
        <!-- <a href="index.html"
        ><img src="../assets/images/img_logo_nav_1.png" alt="hc"
      /></a> -->
      </div>
      <div class="hc-nav">
        <div
          :class="[
            'nav-link-wrap',
            nav.name !== 'product' ? 'relative-pos' : ''
          ]"
          v-for="(nav, index) in headerNav"
          :key="index"
          @mouseover="toggle(index)"
          @mouseleave="active = -1"
        >
          <!-- 其他 -->
          <a
            v-if="nav.direction"
            :exact="index === 0"
            :class="[
              'main-nav-link',
              activeCurNav ? '1' : '2',
              activeCurNav.indexOf(nav.name) > -1 ? 'a-active' : ''
            ]"
            :href="`${nav.direction}`"
            >{{ nav.title }}</a
          >
          <!-- 解决方案和产品中心 无href 不能点击 -->
          <a
            v-else
            :exact="index === 0"
            :class="[
              'main-nav-link',
              activeCurNav ? '1' : '2',
              activeCurNav.indexOf(nav.name) > -1 ? 'a-active' : ''
            ]"
            >{{ nav.title }}</a
          >
          <!-- 解决方案的下拉菜单 -->
          <drop-down-menu
            v-if="active === index && nav.name === 'solution'"
            :menu-items="solutionMenuItems"
            @hideOnClick="hideOnClick"
          ></drop-down-menu>
          <!-- 产品中心的下拉菜单 -->
          <transition name="fade">
            <product-navigation-box
              v-show="active === index && nav.name === 'product'"
            ></product-navigation-box>
          </transition>
          <!--<product-navigation-box-->
          <!--v-show="active === index && nav.name === 'product'"-->
          <!--&gt;</product-navigation-box>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownMenu from "./DropDownMenu";
import ProductNavigationBox from "./product/ProductNavigationBox";
export default {
  name: "Header",
  components: {
    DropDownMenu,
    ProductNavigationBox
  },
  data() {
    return {
      showSolutionNav: false,
      showProductNavBox: false,
      active: -1,
      activeCurNav: "",
      headerNav: [
        { title: "首页", name: "index", direction: "/" },
        // { title: "关于睿峰华创", name: "about", direction: "/about" },
        {
          title: "数字化服务",
          name: "digital-service",
          direction: "/digital-service"
        },
        {
          title: "解决方案",
          name: "solution",
          direction: ""
        }
        // { title: "产品中心", name: "product", direction: "" },
        // { title: "广纳贤才", name: "careers", direction: "/careers" }
      ],
      solutionMenuItems: [
        {
          direction: "/enterprises-digital",
          name: "enterprises-digital",
          title: "数字化运营"
        },
        {
          direction: "/water-digital",
          name: "water-solution",
          title: "水务数字化"
        },
        {
          direction: "/industrial-iot",
          name: "park-solution",
          title: "工业物联网"
        }
      ]
    };
  },
  methods: {
    hideOnClick() {
      this.active = -1;
    },
    hideProductNavBox(isVisible) {
      this.showProductNavBox = isVisible;
    },
    toggle(index) {
      this.active = index;
      localStorage.clear();
    },
    activeNav() {
      // 判断是否当前已激活的导航
      const pathName = window.location.pathname.replace(/^\/|\.html$/g, "");
      this.activeCurNav = pathName ? pathName : "index";
      // console.log("pathName1", this.activeCurNav);
    }
  },
  mounted: function() {
    this.activeNav();
  }
};
</script>

<style lang="less" scoped>
.fade-enter-active {
  transition: opacity 0.6s;
}
.fade-enter {
  opacity: 0;
}

.nav-link-wrap {
  display: inline-block;
}
.nav-link-wrap.relative-pos {
  position: relative;
}

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
}

.hc-header {
  width: 1200px;
  margin: 0 auto;
}
</style>
