<template>
  <div class="hc-introduction bg-light-purple">
    <div class="content hc-introduction-content">
      <div class="hc-intro-detail">
        <h1>关于我们</h1>
        <p class="intro-title">深圳市睿峰华创科技有限公司</p>
        <p>
          深圳市睿峰华创科技有限公司是全面的信息技术与行业解决方案提供商，致力于中小企业的数字化转型升级，帮助企业打造数字化产品，提升企业数字化管理运营能力及数字营销能力；围绕这三个领域提供全面的IT系统解决方案、计算机软、硬件的技术开发和服务及相应的系统集成，通过云计算、物联网、大数据、AI等先进技术以满足客户需求为己任，持续为客户创造价值。睿峰华创积极拓展移动互联网增值服务运营业务，以逐步实现从产品型、技术型企业向服务型、业务型企业的转变。
        </p>
        <a href="/about" class="btn btn-blue">了解更多</a>
      </div>
      <div class="hc-intro-picture">
        <img src="../../assets/images/img_about_company_photo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContentIntroduction"
};
</script>

<style scoped lang="less">
@import "../../assets/css/global";
</style>
