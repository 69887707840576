<template>
  <div>
    <!--专业领域-->
    <div class="section-box core-business">
      <div class="content">
        <h3>专业领域</h3>
        <h6>三大核心领域，专业的场景化解决方案</h6>
        <div class="core-item-box">
          <div
            v-for="(item, index) in items"
            :class="['item', item.className, item.isActive]"
            :key="index"
            @mouseover="mouseOver(item, index, items)"
            :ref="item.className"
          >
            <div data-aos="fade-up" :data-aos-delay="item.delay">
              <div class="img"></div>
              <div class="txt">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="arrow" ref="arrow"></div>
      </div>
    </div>
    <!--单个业务介绍-->
    <div class="business-intro">
      <div
        :class="['business-item', 'content']"
        v-if="this.isMenuShow === 'core-iot'"
      >
        <div class="title-box">
          <div class="business-title">
            <b>工业物联网</b><span>信息技术与现代化生产深度融合、迭代演进</span>
          </div>
          <div>
            <a href="/enterprises-digital" class="btn btn-white">了解更多</a>
          </div>
        </div>
        <div class="business-content">
          <div data-aos="fade-up" data-aos-delay="100">
            <div class="item">
              <div class="cont-icon cont-icon-two"></div>
              <div class="tips"><span>生产</span>·智能</div>
              <div class="cont-intro">
                <p>生产设备智慧化<br />生产设备 · 物联智慧</p>
                <div>
                  管理高效化、监管精细化<br />工业物联网、智慧校园、智慧社区<br />…
                </div>
              </div>
              <div class="cont"></div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="300">
            <div class="item">
              <div class="cont-icon cont-icon-three"></div>
              <div class="tips"><span>企业</span>·繁荣</div>
              <div class="cont-intro">
                <p>经济产业智慧化<br />经济发展 · 产业智慧</p>
                <div>
                  产业数字化、数字产业化<br />工业互联网、安全生产、电子商务<br />…
                </div>
              </div>
              <div class="cont"></div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="500">
            <div class="item">
              <div class="cont-icon cont-icon-one"></div>
              <div class="tips"><span>客户</span>·幸福</div>
              <div class="cont-intro">
                <p>客户服务智慧化<br />社会治理 · 人文智慧</p>
                <div>
                  服务优准化、民生幸福感<br />智慧教育、智慧医疗、智慧养老<br />…
                </div>
              </div>
              <div class="cont"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="['business-item', 'content']"
        v-if="this.isMenuShow === 'core-huanb'"
      >
        <div class="title-box">
          <div class="business-title">
            <b>企业数字化</b><span>覆盖生态环境全数据，为中国生态保驾护航</span>
          </div>
          <div>
            <a href="/wisdom-env-solution" class="btn btn-white">了解更多</a>
          </div>
        </div>
        <div class="business-content">
          <div data-aos="fade-up" class="data-aos">
            <ul>
              <li>
                <span>大气环境监测</span>
                <span>企业电量监控</span>
                <span>AI图像识别</span>
                <span>污染源特征分析</span>
                <span>空气质量预报</span>
              </li>
              <li>
                <span>环境综合分析</span>
                <span>网格监管</span>
                <span>重污染天气应对</span>
                <span>空气质量评估</span>
                <span>节能减排评估</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        :class="['business-item', 'content']"
        v-if="this.isMenuShow === 'core-water'"
      >
        <div class="title-box">
          <div class="business-title">
            <b>水务数字化</b
            ><span>供水排水水务全流程管理，为城市生活供给生命源泉</span>
          </div>
          <div>
            <a href="/water-digital" class="btn btn-white">了解更多</a>
          </div>
        </div>
        <div class="business-content">
          <div data-aos="fade-up" class="data-aos">
            <ul>
              <li>
                <span>水源水质监测</span>
                <span>供排水管理</span>
                <span>GIS管网</span>
                <span>直饮水水质监测</span>
                <span>水利管理</span>
              </li>
              <li>
                <span>防汛抗旱</span>
                <span>SCADA运行调度</span>
                <span>河湖长制管理</span>
                <span>海绵城市</span>
                <span>污水处理监测</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        :class="['business-item', 'content']"
        v-if="this.isMenuShow === 'core-enterprises'"
      >
        <div class="title-box">
          <div class="business-title">
            <b>企业数字化</b><span>让企业运行有轨迹有方向</span>
          </div>
          <div>
            <a href="/industrial-iot" class="btn btn-white">了解更多</a>
          </div>
        </div>
        <div class="business-content">
          <div data-aos="fade-up" class="data-aos">
            <ul>
              <li>
                <span>数字化咨询设计</span>
                <span>数字化过程评估服务</span>
                <span>定制化开发服务</span>
                <span>运维服务</span>
              </li>
              <!-- <li>
                <span>物业管理</span>
                <span>移动OA</span>
                <span>招商管理</span>
                <span>视频监控</span>
                <span>客户管理</span>
              </li>
              <li>
                <span>能耗管理</span>
                <span>门禁管理</span>
                <span>消防管理</span>
                <span>租赁管理</span>
                <span>资产管理</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContentProfessional",
  data() {
    return {
      isCurNavTab: false,
      isMenuShow: "core-enterprises",
      items: [
        {
          text: "企业数字化",
          className: "core-enterprises",
          isActive: "active",
          delay: 100
        },
        {
          text: "水务数字化",
          className: "core-water",
          isActive: "",
          delay: 300
        },
        {
          text: "工业物联网",
          className: "core-iot",
          isActive: "",
          delay: 500
        }
        // {
        //   text: "工业物联网",
        //   className: "core-iot",
        //   isActive: "",
        //   delay: 700
        // }
      ],
      menuItems: [
        {
          title: "企业数字化"
        },
        {
          title: "企业数字化"
        },
        {
          title: "水务数字化"
        },
        {
          title: "工业物联网"
        }
      ],
      offSetLeft: 0,
      offsetWidth: 0
    };
  },
  mounted() {
    this.offSetLeft = this.$refs[this.items[0].className][0].offsetLeft;
    this.offsetWidth = this.$refs[this.items[0].className][0].offsetWidth;
  },
  watch: {
    offSetLeft: function(e) {
      this.$refs.arrow.style.cssText = `left: ${e + this.offsetWidth / 2}px`;
    }
  },
  methods: {
    mouseOver(item, index, items) {
      this.offSetLeft = this.$refs[item.className][0].offsetLeft;
      this.isCurNavTab = true;
      item.isActive = this.isCurNavTab ? "active" : "";
      this.isMenuShow = item.className;
      items.map(function(itm) {
        if (itm.text !== item.text) {
          itm.isActive = "";
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/css/global";

/*箭头样式*/
.arrow {
  transition: all 0.5s ease-in-out;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-bottom: 16px solid #303ca8;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  position: absolute;
  bottom: 0;
}

.section-box {
  width: 100%;
}

.core-business {
  text-align: center;
  height: 420px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;

  h3 {
    color: @font-color-active;
    font-size: 32px;
    line-height: 32px;
  }
  h6 {
    font-size: 20px;
    line-height: 20px;
    margin-top: 16px;
  }
  .core-item-box {
    cursor: pointer;
    margin-top: 44px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    & > .item {
      position: relative;
      .img {
        width: 120px;
        height: 120px;
      }
      .txt {
        font-size: 18px;
        color: @font-color-default;
        margin-top: 20px;
      }
      &:hover {
        .txt {
          color: @font-color-active;
        }
      }
    }
    & > .core-enterprises {
      .img {
        background: url("../../assets/images/icon_city.png") center no-repeat;
      }
      &:hover {
        .img {
          background: url("../../assets/images/icon_city_cur.png") center
            no-repeat;
        }
      }
    }
    & > .core-water {
      .img {
        background: url("../../assets/images/icon_water.png") center no-repeat;
      }
      &:hover {
        .img {
          background: url("../../assets/images/icon_water_cur.png") center
            no-repeat;
        }
      }
    }
    & > .core-huanb {
      .img {
        background: url("../../assets/images/icon_huanb.png") center no-repeat;
      }
      &:hover {
        .img {
          background: url("../../assets/images/icon_huanb_cur.png") center
            no-repeat;
        }
      }
    }
    & > .core-iot {
      .img {
        background: url("../../assets/images/icon_park.png") center no-repeat;
      }
      &:hover {
        .img {
          background: url("../../assets/images/icon_park_cur.png") center
            no-repeat;
        }
      }
    }
  }
}

.business-intro {
  width: 100%;
  background: @font-color-active url("../../assets/images/img_core_info_bg.png")
    no-repeat center;
  background-size: cover;
  padding-bottom: 106px;
  height: 660px;
  .active {
    display: block;
  }
  .business-item {
    /*display: none;*/

    color: #fff;
    .title-box {
      padding: 44px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      .business-title {
        font-size: 20px;
        display: flex;
        align-items: center;
        & > b {
          font-size: 32px;
          padding-right: 20px;
          border-right: 1px solid rgba(255, 255, 255, 0.16);
          font-weight: normal;
        }
        & > span {
          padding-left: 20px;
        }
      }
    }
    .business-content {
      display: flex;
      justify-content: space-between;
      .data-aos {
        width: 100%;
      }
      .item {
        text-align: center;
        width: 320px;
        border-radius: 4px;

        .tips {
          font-size: 24px;
        }
        width: 320px;
        height: 400px;
        padding: 43px 0 0;
        background: rgba(13, 25, 136, 0.3);
        margin-top: 50px;
        .cont-icon {
          width: 90px;
          height: 90px;
          margin: 0 auto 27px;
          border-radius: 28px;
        }
        .cont-icon-one {
          background: url("../../assets/images/icon_zhcs_1.png") center
            no-repeat;
          background-size: 100% 100%;
        }
        .cont-icon-two {
          background: url("../../assets/images/icon_zhcs_2.png") center
            no-repeat;
          background-size: 100% 100%;
        }
        .cont-icon-three {
          background: url("../../assets/images/icon_zhcs_3.png") center
            no-repeat;
          background-size: 100% 100%;
        }
        .cont-intro {
          & > p {
            font-size: 18px;
            line-height: 24px;
            margin: 0 40px;
            padding: 22px 0 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          }
          & > div {
            line-height: 22px;
            padding-top: 20px;
          }
        }
      }
      ul {
        width: 100%;
        padding: 120px 0 180px;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 80px;
          &:first-child {
            margin-top: 0;
          }
          & > span {
            display: inline-block;
            width: 200px;
            height: 80px;
            line-height: 80px;
            font-size: 18px;
            text-align: center;
            background: rgba(13, 25, 136, 0.3);
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
