<template>
  <div class="rf-step-root">
    <div class="content step-content-parent">
      <div class="step-title">如何成功构建 数字化经营能力</div>
      <div class="step-wrapper">
        <template v-for="(item, index) in steps">
          <div class="step-item" :key="index">
            <img class="item-icon" :src="item.icon" />
            <span class="item-label">{{ item.label }}</span>
            <div class="divider"></div>
            <span class="item-en">{{ item.en }}</span>
          </div>
        </template>
      </div>
      <img src="../../assets/images/home-content-step-index.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContentStep",
  data() {
    return {
      steps: [
        {
          icon: require("../../assets/images/home-step-icon-1.png"),
          label: "业务全面评估",
          en: "Comprehensive business evaluation"
        },
        {
          icon: require("../../assets/images/home-step-icon-2.png"),
          label: "专属解决方案",
          en: "Dedicated solution"
        },
        {
          icon: require("../../assets/images/home-step-icon-3.png"),
          label: "交付落地与陪跑",
          en: "Delivery landing and accompanying run"
        },
        {
          icon: require("../../assets/images/home-step-icon-4.png"),
          label: "客户持续成功",
          en: "Sustained customer success"
        }
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/css/global";

.rf-step-root {
  min-width: 1200px;
  height: 660px;
  background-image: url("../../assets/images/home-content-step-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .step-content-parent {
    width: 1200px;
    display: flex;
    flex-flow: column nowrap;

    .step-title {
      font-size: 32px;
      line-height: 120px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      box-sizing: border-box;
      text-align: center;
      color: #fff;
    }

    .step-wrapper {
      width: 100%;
      margin: 50px 0 10px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .step-item {
        width: 267px;
        height: 340px;
        background: rgba(0, 41, 106, 0.3);
        border-radius: 4px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        color: #fff;

        .item-icon {
          width: 96px;
          height: 96px;
          margin: 40px 0 24px;
        }

        .item-label {
          font-size: 24px;
          padding-bottom: 24px;
          // bottom: 1px solid rgba(255, 255, 255, 0.16);
        }
        .divider {
          width: 200px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.16);
        }
        .item-en {
          width: 194px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          margin-top: 14px;
          font-family: PingFangSC-Regular, PingFang SC, Microsoft Yahei,
            sans-serif;
          // font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // opacity: 0.75;
        }
      }
    }
  }
}
</style>
